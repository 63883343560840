import React from 'react';
import ScaleLoader	from "react-spinners/ScaleLoader";

function LoadingComponent() {
  
  return (
    <div className={`bg-[#1D183D] h-full w-full flex justify-center items-center`}>
      <ScaleLoader	color="#ffffff" size={150}/>
    </div>
  )
}

export default LoadingComponent
