export const initialState = {
  width: 0,
};

export const actionTypes = {
  SET_WIDTH: "SET_WIDTH",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    default:
      return state;
  }
};

export default reducer;
