import React, { Suspense, useEffect, useState } from 'react'
// import { useStateValue } from '../utils/state';
import coinGecko from '../utils/coinGecko';
import LoadingComponent from '../components/LoadingComponent';
import Sidebar from '../components/Sidebar';
const Header = React.lazy(() => import('../components/Header'));
const Button = React.lazy(() => import('../components/Button'));
const ChartContainer = React.lazy(() => import('../components/ChartContainer'));
const SmallChartContainer = React.lazy(() => import('../components/SmallChartContainer'));
const CoinList = React.lazy(() => import('../components/CoinList'));
const CoinSummary = React.lazy(() => import('../components/CoinSummary'));

function Home() {
  // const [state, dispatch] = useStateValue();
  const [coins, setCoins] = useState(localStorage.getItem('coins'));
  const [activeCoin, setActiveCoin] = useState();
  const [height, setHeight] = useState('h-screen');
  const [data, setData] = useState([])
  const [trendingCoins, setTrendingCoins] = useState([])
  const [trendingData, setTrendingData] = useState([])
  const [format, setFormat] = useState('1');
  const [currency, setCurrency] = useState(localStorage.getItem('currency'))
  const [time, setTime] = useState(Date.now());

  /* Format Trending Names */
  const getTrendingNames = () => {
    return trendingCoins.map(coin => coin.item.id).join(',')
  }

  useEffect(() => {
    if (!localStorage.getItem('coins')) {
      localStorage.setItem('coins', 'bitcoin,ethereum,tether,ripple,bitcoin-cash,xrp-classic,litecoin,cardano')
    } else {
      setCoins(localStorage.getItem('coins'))
    }
  }, [coins])

  useEffect(() => {
    if (!localStorage.getItem('currency')) {
      localStorage.setItem('currency', 'usd')
    } else {
      setCurrency(localStorage.getItem('currency'))
    }
  }, [currency])

  /* Get stored coins data */
  useEffect(() => {
    async function fetchData() {
      await
        coinGecko.get('/coins/markets/', {
          params: {
            vs_currency: currency,
            ids: coins,
          }
        })
        .then(res => {
          setData(res.data);
          setActiveCoin(res.data[0])
        }).catch(error => console.error(error))
      }
      fetchData();
  }, [coins, currency, time])

  /* Get Trending Coins */
  useEffect(() => {
    async function fetchTrending() {
      await
        coinGecko.get('/search/trending/')
        .then(res => {
          setTrendingCoins(res.data.coins);
        }).catch(error => console.error(error))
      }
      fetchTrending();
  }, [time])

  /* Get trending coins data */
  useEffect(() => {
    if (trendingCoins.length === 7) {
      async function fetchTrending() {
        await
          coinGecko.get('/coins/markets/', {
            params: {
              vs_currency: currency,
              ids: getTrendingNames(),
            }
          })
          .then(res => {
            setTrendingData(res.data);
          }).catch(error => console.error(error))
        }
        fetchTrending();
    }
  //eslint-disable-next-line
  }, [trendingCoins, currency, time])

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000*60*4);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div onLoad={()=>setHeight('h-full')} className={`${height} flex w-full overflow-y-auto overflow-x-auto min-w-[23.125rem]`}>
      <Sidebar setCurrency={setCurrency} currency={currency}/>
      <div className='flex flex-col justify-evenly items-center xl:items-start xl:flex-row w-full h-full overflow-y-auto bg-[#1D183D]'>
        <div className="w-[90%] m-5 flex flex-col justify-center items-center">
          <Suspense fallback={<LoadingComponent/>}>
            <Header />
          </Suspense>
          <Suspense fallback={<LoadingComponent/>}>
            <ChartContainer currency={currency} format={format} setFormat={setFormat} activeCoin={activeCoin}/>
            <SmallChartContainer currency={currency} formatID={format} activeCoin={activeCoin}/>
          </Suspense>
          <Suspense fallback={<LoadingComponent/>}>
            <CoinSummary currency={currency} activeCoin={activeCoin}/>
          </Suspense>
          <div className="flex w-full justify-evenly items-center">
            <Suspense fallback={<LoadingComponent/>}>
              <Button currency={currency} activeCoin={activeCoin} cryptoName={activeCoin?.name} type="buy"/>
              <Button currency={currency} activeCoin={activeCoin} cryptoName={activeCoin?.name} type="sell"/>
            </Suspense>
          </div>
          <div className="w-full flex m-5 items-center justify-center">
            <Suspense fallback={<LoadingComponent/>}>
              <CoinList currency={currency} setCoins={setCoins} trendingData={trendingData} setActiveCoin={setActiveCoin} data={data}/>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Home
