import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
import 'animate.css';

export const createNotification = (title, message, type) => {
  store.addNotification({
    title: title,
    message: message,
    type: type,
    container: 'top-right',
    insert: 'top',
    isMobile: true,
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  })
}