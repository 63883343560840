import Home from './pages/Home'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import Wallet from './pages/Wallet'
import { useEffect } from 'react'
import { createNotification } from './utils/createNotification'

function App() {
	// Check for internet connectivity
	useEffect(() => {
		const connection_status = navigator.onLine
		if (connection_status) {
			fetch('https://www.google.com/', {
				mode: 'no-cors',
			})
				.then(() => {
					console.log('%c CONNECTED TO INTERNET', 'color:green; font-size: 16px')
				})
				.catch(() => {
					console.log('%c INTERNET CONNECTIVITY ISSUE', 'color:red; font-size: 16px')
					createNotification('ERROR', `❌😥 Internet connection is lost!`, 'danger')
				})
		}
	}, [])

	return (
		<Router basename='/'>
			<Switch>
				<Route path='/' exact component={Home} />
				<Route path='/home' exact component={Home} />
				<Route path='/wallet' exact component={Wallet} />
				<Route component={NotFound} />
			</Switch>
		</Router>
	)
}

export default App
