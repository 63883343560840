import React, { Suspense, useEffect, useState } from 'react'
import axios from 'axios'
import LoadingComponent from '../components/LoadingComponent'
import { createNotification } from '../utils/createNotification'
const CoinAssets = React.lazy(() => import('../components/CoinAssets'))
const DepositHistory = React.lazy(() => import('../components/DepositHistory'))
const WithdrawHistory = React.lazy(() => import('../components/WithdrawHistory'))
const Sidebar = React.lazy(() => import('../components/Sidebar'))

function Wallet() {
	const [accountData, setAccountData] = useState()
	const [depositHistory, setDepositHistory] = useState()
	const [withdrawHistory, setWithdrawHistory] = useState()
	const [timestamp, setTimestamp] = useState('')
	const [currency, setCurrency] = useState(localStorage.getItem('currency'))

	useEffect(() => {
		async function fetchTimestamp() {
			await axios
				.get('http://localhost:3001/timestamp')
				.then(res => setTimestamp(res.data))
				.catch(error => {
					console.error(error)
					createNotification('ERROR', `❌😥 ${error.message}`, 'danger')
				})
		}
		fetchTimestamp()
	}, [])

	useEffect(() => {
		async function fetchAccountData() {
			await axios
				.get('http://localhost:3001/accountInfo')
				.then(res => setAccountData(res.data))
				.catch(error => {
					console.error(error)
					createNotification('ERROR', `❌😥 ${error.message}`, 'danger')
				})
		}
		fetchAccountData()
	}, [])

	useEffect(() => {
		async function fetchDepositHistorytData() {
			await axios
				.get('http://localhost:3001/depositHistory')
				.then(res => setDepositHistory(res.data))
				.catch(error => {
					console.error(error)
					createNotification('ERROR', `❌😥 ${error.message}`, 'danger')
				})
		}
		fetchDepositHistorytData()
	}, [])

	useEffect(() => {
		async function fetchWithdrawHistorytData() {
			await axios
				.get('http://localhost:3001/withdrawHistory')
				.then(res => setWithdrawHistory(res.data))
				.catch(error => {
					console.error(error)
					createNotification('ERROR', `❌😥 ${error.message}`, 'danger')
				})
		}
		fetchWithdrawHistorytData()
	}, [])

	useEffect(() => {
		if (!localStorage.getItem('currency')) {
			localStorage.setItem('currency', 'usd')
		} else {
			setCurrency(localStorage.getItem('currency'))
		}
	}, [currency])

	return (
		<div className='bg-[#1D183D] flex w-full h-screen overflow-y-auto overflow-x-auto'>
			<Suspense fallback={<LoadingComponent />}>
				<Sidebar currency={currency} setCurrency={setCurrency} />
			</Suspense>
			<div className='flex flex-col justify-center items-center w-full'>
				<div className='relative flex flex-col w-[90%] h-[90%] p-10 bg-gray-500 bg-opacity-10 rounded-xl border-[1px] border-white border-opacity-10 shadow-lg'>
					<h2 className='text-gray-300 font-bold text-xl text-center sm:text-2xl md:text-4xl lg:text-left lg:text-5xl p-5'>
						Konstantinos Angelopoulos
					</h2>
					<h4 className='text-blue-400 font-bold text-lg lg:text-2xl p-5 underline text-center sm:text-left'>
						Balance
					</h4>
					<h4 className='bg-[#04d9ff] bg-opacity-50 text-black text-opacity-70 text-center font-bold text-2xl border-[2.5px] border-white border-opacity-30 shadow-xl lg:text-4xl sm:ml-10 p-5 rounded-xl'>
						{accountData?.balances.length !== 0 ? accountData?.balances : '0'}
						{currency === 'usd' ? '$' : '€'}
					</h4>
					<Suspense fallback={<LoadingComponent />}>
						<CoinAssets coinAssets={accountData?.balances} />
					</Suspense>
					<Suspense fallback={<LoadingComponent />}>
						<DepositHistory depositHistory={depositHistory} />
					</Suspense>
					<Suspense fallback={<LoadingComponent />}>
						<WithdrawHistory withdrawHistory={withdrawHistory} />
					</Suspense>
					<h3 className='absolute bottom-[2rem] right-0 left-0 sm:bottom-[2rem] sm:right-[2rem] text-center sm:text-right text-md font-bold text-indigo-200 text-opacity-50'>
						{timestamp}
					</h3>
				</div>
			</div>
		</div>
	)
}

export default Wallet
