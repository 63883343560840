import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import EuroIcon from '@material-ui/icons/Euro';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';

function Sidebar({ currency, setCurrency }) {

  const [showMenu, setShowMenu] = useState(false);
  const [activePage, setActivePage] = useState(window.location.pathname)
  const history = useHistory();
  
  const handleHomeHistory = () => {
    if (window.location.pathname !== '/' && window.location.pathname !== '/home') {
      history.push('/')
    }
  }

  const handleWalletHistory = () => {
    if (window.location.pathname !== '/wallet') {
      history.push('/wallet')
    }
  }

  const handleChangeCurrency = (curr) => {
    setCurrency(curr)
    localStorage.setItem('currency', curr)
  }

  useEffect(() => {
    setActivePage(window.location.pathname);
  }, [])

  return (
    <div className={`${showMenu ? 'w-[12rem]' : 'w-[4rem] min-width-[4rem]'} transition-[width] duration-200 ease-linear flex flex-col items-center bg-gradient-to-b from-[#1E1C40] via-[#151449] to-[#222050] border-r-[2px] border-white border-opacity-10 overflow-y-auto overflow-x-hidden`}>
      <div className={`flex flex-row w-full ${showMenu ? 'justify-center' : 'justify-start'} sm:justify-evenly items-center text-center text-pink-600 text-xl rounded-[0.25rem] shadow-sm border-white border-opacity-10 ${showMenu && 'border-b-[2px]'}`}>
        <div className={`m-1 ${showMenu ? 'px-5' : 'px-[0.5rem]'} grid grid-cols-2 rounded-xl text-2xl`}>
          {showMenu ? 
            <CloseIcon onClick={() => setShowMenu(!showMenu)} className="my-1 w-15 h-15 cursor-pointer hover:text-purple-400"/> 
          :
            <MenuIcon onClick={() => setShowMenu(!showMenu)} className="my-1 mx-[0.4rem] w-15 h-15 cursor-pointer hover:text-purple-400"/>
          }
          {showMenu && <h3 className="mx-3 text-center flex justify-center items-center font-bold"> Menu </h3>}
        </div>
      </div>
      <div className="w-full flex flex-col justify-evenly text-gray-200 text-center text-lg">
        <div onClick={()=>handleHomeHistory()} className={`m-1 ${showMenu ? 'px-5' : 'px-[0.9rem]'} py-3 grid grid-cols-2 ${activePage === '/home' || activePage === '/' ? 'text-pink-600 shadow-lg font-bold border-[0.5px]' : 'hover:text-purple-400 hover:shadow-lg hover:font-bold hover:border-[0.5px]'}  border-gray-900 border-opacity-50 cursor-pointer rounded-xl`}>
          <HomeIcon className="w-15 h-15" />
          {showMenu && <h3> Home </h3> }
        </div>
        <div onClick={()=>handleWalletHistory()} className={`m-1 ${showMenu ? 'px-5' : 'px-[0.9rem]'} py-3 grid grid-cols-2 ${activePage === '/wallet' ? 'text-pink-600 shadow-lg font-bold border-[0.5px]' : 'hover:text-purple-400 hover:shadow-lg hover:font-bold hover:border-[0.5px]'} border-gray-900 border-opacity-50 cursor-pointer rounded-xl`}>
          <AccountBalanceWalletIcon className="w-15 h-15" />
          {showMenu && <h3> Wallet </h3> }
        </div>
          {currency === 'usd' ? (
              <div onClick={()=>handleChangeCurrency('eur')} className={`m-1 ${showMenu ? 'px-5' : 'px-[0.9rem]'} py-3 grid grid-cols-2 hover:text-purple-400 hover:shadow-lg hover:font-bold hover:border-[0.5px] border-gray-900 border-opacity-50 cursor-pointer rounded-xl`}>
                <MonetizationOnIcon />
                {showMenu && <h3> USD </h3> }
              </div>
            ) : (
              <div onClick={()=>handleChangeCurrency('usd')} className={`m-1 ${showMenu ? 'px-5' : 'px-[0.9rem]'} py-3 grid grid-cols-2 hover:text-purple-400 hover:shadow-lg hover:font-bold hover:border-[0.5px] border-gray-900 border-opacity-50 cursor-pointer rounded-xl`}>
                <EuroIcon />
                {showMenu && <h3> EURO </h3> }
              </div>
            )}
      </div>
    </div>
  )
}

export default Sidebar
